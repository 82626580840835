import React from 'react';
import { useFieldError } from '@wearejh/rx-form/lib/hooks/useFieldError';

import errors from '../../error-messages.json';

import classes from './Forms.scss';

export function ErrorFor(props: { field: string }) {
    const error = useFieldError(props.field);
    if (error) {
        if (errors[props.field] && errors[props.field][error]) {
            return <ErrorText text={errors[props.field][error]} />;
        }
        return <ErrorText text={error} />;
    }

    return null;
}

export function ErrorText(props: { text: string }) {
    return (
        <div className={classes.inputError}>
            <span className={classes.inputErrorText}>{props.text}</span>
        </div>
    );
}
